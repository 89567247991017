@import "https://fonts.googleapis.com/css2?family=Fredoka+One&family=Roboto:wght@300&display=swap";
html, body {
  height: 100%; }

body {
  height: 100%; }

h1, h2, h3, h4, h5 {
  font-family: 'Roboto', sans-serif; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.navigationbar .logo {
  color: #000;
  text-decoration: none;
  font: 900 24px/26px 'Fredoka One', cursive; }
  .navigationbar .logo span {
    color: #FF0080 !important; }

.navigationbar ul {
  width: 100%;
  /* Your element needs a fixed width */
  display: flex;
  justify-content: space-between;
  margin: 25px 0 0; }

.sidenav {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  border-left: 1px solid #aeaeae;
  height: 100%;
  background: #FFF;
  z-index: 999; }
  .sidenav .close {
    margin: 25px 141px 0 0;
    text-align: center; }
  .sidenav ul {
    margin-top: 150px;
    margin-left: 25px;
    content: "";
    clear: both;
    display: table; }
    .sidenav ul li {
      font: 900 16px/18px 'Roboto', cursive;
      margin-bottom: 15px;
      text-transform: uppercase; }

.article-container {
  margin-top: 50px; }
  .article-container h1 {
    font-size: 1.875rem;
    line-height: 1;
    font-weight: 800; }
  .article-container .writer {
    margin-top: 15px; }
  .article-container .article-cotent {
    margin-top: 15px; }
  .article-container .photo-credit {
    font-size: 11px; }

.footer {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  text-align: center;
  margin-top: 25px; }
  .footer span {
    text-align: center; }
